import "./CaseStudyDetail.scss";

import { graphql, PageProps } from "gatsby";
import React, { FunctionComponent } from "react";
import WidgetBuilder from "Shared/components/builders/WidgetBuilder";
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from "Shared/components/content/mediaImage/PuxMediaSelector";
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from "Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs";
import { PuxKeyValueType } from "Shared/components/EasySoftwareCaseStudies/EasySoftwareCaseStudyItem";
import { localizedPathType } from "Shared/components/PuxLanguageSelector/PuxLanguageSelector";
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from "Shared/components/PuxWysiwyg/PuxWysiwyg";
import { WidgetPuxSectionType } from "Shared/components/widgets/WidgetPuxSection";
import { getUrlWithProtocol } from "Shared/utils/getUrlWithProtocol";
import PuxMetaTags, { PuxMetaTagsType } from "Shared/utils/PuxMetaTags";

import Layout from "../../components/core/Layout";

export interface CaseStudyType {
  contentType: string;
  contentItemId: string;
  contentItemVersionId: string;
  path: string;
  displayText: string;
  localization: {
    localizations: localizedPathType[];
  };
  caseStudyUrl: string;
  caseStudyPerex: PuxWysiwygHtmlBodyType;
  caseStudyContent: PuxWysiwygHtmlBodyType;
  caseStudyImage: PuxMediaSelectorType;
  caseStudyTopImage?: PuxMediaSelectorType;
  caseStudyParameters: {
    contentItems: [PuxKeyValueType];
  };
}

export interface CaseStudyDetail extends PageProps {
  data: {
    orchard: {
      easySoftwareCaseStudy: [CaseStudyType];
    };
  };
  pageContext: {
    pagePath: string;
    metaTags: PuxMetaTagsType;
    breadcrumbs: [BreadcrumbItem];
    widgets: {
      caseStudyWidgets: [WidgetPuxSectionType];
    };
  };
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareCaseStudy(first: 1, where: { path: $pagePath }) {
        contentType
        contentItemId
        path
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwareCaseStudy {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        caseStudyUrl
        caseStudyPerex {
          html
        }
        caseStudyContent {
          html
        }
        caseStudyTopImage {
          mediaSelectorImage {
            resizePaths(width: 1920, mode: "Min", quality: 75)
            resizePathsMobile(width: 768, mode: "Min", quality: 75)
            resizePathsTablet(width: 1200, mode: "Min", quality: 75)
          }
        }
        caseStudyImage {
          mediaSelectorImage {
            resizePaths(width: 1920, mode: "Min", quality: 75)
            resizePathsMobile(width: 768, mode: "Min", quality: 75)
            resizePathsTablet(width: 1200, mode: "Min", quality: 75)
          }
        }
        caseStudyParameters {
          contentItems {
            ... on Orchard_PuxKeyValue {
              displayText
              contentItemId
              keyValueContent {
                html
              }
            }
          }
        }
      }
    }
  }
`;

const rootClass = `case-study-detail`;

const CaseStudyDetail: FunctionComponent<CaseStudyDetailType> = (props) => {
  const pageData = props.data.orchard.easySoftwareCaseStudy[0];
  const breadCrumbsData = props.pageContext.breadcrumbs;
  const widgets = props.pageContext.widgets;
  const hasTopImage =
    pageData.caseStudyTopImage?.mediaSelectorImage.resizePaths.length;

  return (
    <Layout localizedPath={pageData.localization?.localizations}>
      {!hasTopImage && <EasySoftwareBreadcrumbs items={breadCrumbsData} />}
      <PuxMetaTags {...props.pageContext.metaTags} />
      <div className={rootClass}>
        {hasTopImage && (
          <div className={`${rootClass}-top-image`}>
            <EasySoftwareBreadcrumbs items={breadCrumbsData} />
            <PuxMediaSelector {...pageData.caseStudyTopImage!} />
            <div className="pux-container pux-container-thin">
              <h1 className={`${rootClass}-title`}>{pageData.displayText}</h1>
            </div>
          </div>
        )}
        <div className="pux-container pux-container-thin">
          {!hasTopImage && (
            <h1 className={`${rootClass}-title`}>{pageData.displayText}</h1>
          )}
          <div className={`${rootClass}-meta`}>
            <div className={`${rootClass}-image`}>
              <PuxMediaSelector {...pageData.caseStudyImage} />
            </div>
            <div className={`${rootClass}-parameters`}>
              {pageData.caseStudyParameters &&
                pageData.caseStudyParameters.contentItems &&
                pageData.caseStudyParameters.contentItems.map((item) => (
                  <div
                    className={`${rootClass}-parameter`}
                    key={item.contentItemId}
                  >
                    <strong className={`${rootClass}-parameter-label`}>
                      {item.displayText}:
                    </strong>
                    <div className={`${rootClass}-parameter-value`}>
                      <PuxWysiwyg inline content={item.keyValueContent} />
                    </div>
                  </div>
                ))}
            </div>
            {pageData.caseStudyUrl && (
              <div className={`${rootClass}-url`}>
                <a
                  href={getUrlWithProtocol(pageData.caseStudyUrl) || ``}
                  target="_blank"
                  rel="noopener"
                >
                  {pageData.caseStudyUrl}
                </a>
              </div>
            )}
          </div>

          <div className={`${rootClass}-perex perex`}>
            <PuxWysiwyg content={pageData.caseStudyPerex} />
          </div>

          <div className={`${rootClass}-content`}>
            <PuxWysiwyg content={pageData.caseStudyContent} />
          </div>
        </div>
      </div>
      <div className="pux-container pux-container-thin pux-container-widget-builder">
        <WidgetBuilder widgetBuilderData={widgets?.caseStudyWidgets} />
      </div>
    </Layout>
  );
};

export default CaseStudyDetail;
