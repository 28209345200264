import { Link } from 'gatsby'
import React, { FunctionComponent } from 'react'
import { getDomainByCulture } from 'Root/Shared/utils/getHreflangsForHelmet'
import { WidgetData } from 'Shared/components/builders/WidgetBuilder'
import PuxIconBuilder from 'Shared/components/PuxIconBuilder/PuxIconBuilder'
import {
  getFromConfig,
  getHrefLang,
  getLocalizedUrl,
} from 'Shared/utils/localeURL'

import PuxStructuredData from '../PuxStructuredData/PuxStructuredData'

const ER_HOME_LABEL = `Easy Redmine`
const EP_HOME_LABEL = `Easy Project`
const ES_HOME_LABEL = `Easy Software`
const getHomeLabel = (): string => {
  switch (process.env.PROJECT) {
    case `EasyRedmineGatsby`:
      return ER_HOME_LABEL
    case `EasyProjectGatsby`:
      return EP_HOME_LABEL
    case `EasySoftwareGatsby`:
      return ES_HOME_LABEL
    default:
      return ``
  }
}

const localizedHomepageUrl = `/${getFromConfig(
  process.env.LOCALE ?? `en`,
  `urlPrefix`,
  process.env.PROJECT ?? `EasyRedmineGatsby`
)}`

const activeIsoCode = getFromConfig(
  process.env.LOCALE ?? `en`,
  `isoCode`,
  process.env.PROJECT ?? `EasyRedmineGatsby`
)

export interface BreadcrumbsType {
  items: [BreadcrumbItem]
  showAll?: boolean
}

export interface BreadcrumbItem {
  displayText: string
  path: string
}

export interface BreadcrumbJson {
  '@context': string
  '@type': string
  itemListElement: BreadcrumbJsonItem[]
}

interface BreadcrumbJsonItem {
  '@type': string
  position: number
  name: string
  item: string
}

export function breadcrumbsOnTopImage(widgetDataArray: [WidgetData]): boolean {
  const foundTopImage: boolean[] = []
  widgetDataArray.map((widgetData, index) => {
    if (widgetData.contentType === `PuxTopImage` && index === 0) {
      return foundTopImage.push(true)
    }
    if (widgetData.contentType === `PuxSection` && index === 0) {
      widgetData.flow.widgets.map((w, i) => {
        if (w.contentType === `PuxTopImage` && i === 0) {
          return foundTopImage.push(true)
        }
        return foundTopImage.push(false)
      })
    }
    return foundTopImage.push(false)
  })

  return foundTopImage.includes(true)
}

function getBreadcrumbsJson(items: [BreadcrumbItem]) {
  const json: BreadcrumbJson = {
    '@context': `https://schema.org`,
    '@type': `BreadcrumbList`,
    itemListElement: [],
  }

  items?.map((item, index) => {
    const localizedPath = getLocalizedUrl(item.path)
    const domain = getDomainByCulture(activeIsoCode)
    const finalPath = localizedPath.startsWith(`http`)
      ? localizedPath
      : domain + localizedPath

    const jsonItem: BreadcrumbJsonItem = {
      '@type': `ListItem`,
      position: index + 1,
      name: item.displayText,
      item: finalPath,
    }
    json.itemListElement.push(jsonItem)
  })

  return json
}

const EasySoftwareBreadcrumbs: FunctionComponent<BreadcrumbsType> = (props) => {
  const { items, showAll } = props

  const homeLabel = getHomeLabel()
  const home = (
    <Link
      className='breadcrumbs-item'
      to={`${localizedHomepageUrl}`}
      key={`home`}
    >
      <PuxIconBuilder icon='HomeIcon' />
      <span>{homeLabel}</span>
    </Link>
  )

  const buildBreadcrumbs = (items: [BreadcrumbItem]) => (
    <div className={`breadcrumbs-wrap`}>
      <PuxStructuredData structuredData={getBreadcrumbsJson(items)} />
      <div className='pux-container'>
        <div className='breadcrumbs'>
          {home}
          {items?.map((item, index) => {
            return (
              <Link
                className='breadcrumbs-item'
                to={`${getLocalizedUrl(item.path)}`}
                key={index}
              >
                <span>{item.displayText}</span>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )

  if (items && items.length > 1) {
    return buildBreadcrumbs(items)
  } else if (showAll) {
    return buildBreadcrumbs(items)
  } else {
    return null
  }
}

export default EasySoftwareBreadcrumbs
